import { createContext, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import AboutMe from "./components/AboutMe";
import Education from "./components/Education";
import Experience from "./components/Experience";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Presentation from "./components/Presentation";
import ResearchExperience from "./components/ResearchExperience";
import Games from "./components/Games"; // Import the Games component

export const DarkModeContext = createContext(true);

function App() {
  const [isDarkMode, setIsDarkMode] = useState(true);

  const onMouseMove = (e) => {
    const eyes = document.querySelectorAll(".penguin-eye");
    [].forEach.call(eyes, function (eye) {
      let mouseX = eye.getBoundingClientRect().right;
      if (eye.classList.contains("penguin-eye-left")) {
        mouseX = eye.getBoundingClientRect().left;
      }
      let mouseY = eye.getBoundingClientRect().top;
      let radianDegrees = Math.atan2(e.pageX - mouseX, e.pageY - mouseY);
      let rotationDegrees = radianDegrees * (180 / Math.PI) * -1 + 180;
      eye.style.transform = `rotate(${rotationDegrees}deg)`;
    });
  };

  const HomePage = () => {
    return (
      <>
        <AboutMe />
        <Education />
        <Presentation />
        <ResearchExperience />
        <Experience />
        <Footer />
      </>
    );
  };

  const NotFoundPage = () => {
    return (
      <div className="h-screen text-center">
        <h1 className="text-4xl font-bold">404</h1>
        <p>Page Not Found</p>
      </div>
    );
  };

  return (
    <DarkModeContext.Provider value={{ isDarkMode, setIsDarkMode }}>
      <Router>
        <div
          className={`App ${isDarkMode ? "dark" : "light"}`}
          onMouseMove={onMouseMove}
        >
          <Header />
          <div className="App-body">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/games/chicken" element={<Games />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </div>
      </Router>
    </DarkModeContext.Provider>
  );
}

export default App;
