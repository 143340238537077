import React, { useContext } from "react";
import { DarkModeContext } from "../App";

export default function Footer() {
  const { isDarkMode } = useContext(DarkModeContext);
  return (
    <>
      <style>
        {`
            .footer {
                font-family: 'DM Sans';
                color: ${isDarkMode ? "rgba(229, 231, 235, 1)" : "black"};
                opacity: 0.5;
                padding: 66px 16px 24px 15px;
                text-align: center;
            }`}
      </style>
      <footer className="footer">
        <span>{"© Syed Nabil Ashraf 2022"}</span>
        <br></br>
        {/* A penguin who wants to fly */}
      </footer>
    </>
  );
}
