import React, { useContext } from "react";
import { DarkModeContext } from "../App";
import './Experience.scss'

export default function Presentation() {
    const { isDarkMode } = useContext(DarkModeContext);

    return <div className='experience'>
        <h2 className='experience-title App-sectionHeader'>Publications</h2>
        <ul>
            <li>
        <span className={`aboutMe-description ${isDarkMode ? 'dark' : 'light'}`}>
                <a  href="https://scholar.google.com/citations?view_op=view_citation&hl=en&user=H_1zL2sAAAAJ&citation_for_view=H_1zL2sAAAAJ:u5HHmVD_uO8C" target='_blank'> 

            “SynthEnsemble: A Fusion of CNN, Vision Transformer, and Hybrid Models for Multi-Label Chest X-Ray Classification”, 2023 26th International Conference on Computer and Information Technology (ICCIT)

          </a>
        </span>
            </li>
        </ul>
    </div>
}
