import React from "react";
import './Experience.scss'

export default function Experience() {
    return <div className='experience'>
        <h2 className='experience-title App-sectionHeader'>Industry Experience</h2>
        {/* FULFLLD */}
        <div className='experience-headerContainer'>
            <div>
                <p className='experience-headerContainer-role'>Software Engineer (Remote)</p>
                <a className='experience-headerContainer-companyName' href="https://www.fulflld.com/" target='_blank'>FULFLLD</a>
            </div>
            <date className='experience-headerContainer-duration'>March 2024 - Present</date>
        </div>
        <ul>
            <li>
Led the development of order management and route optimization feature in a delivery service provider startup currently operating in USA
            </li>
        </ul>

        {/* Optimizely */}
        <div className='experience-headerContainer'>
            <div>
                <p className='experience-headerContainer-role'>Software Engineer</p>
                <a className='experience-headerContainer-companyName' href="https://www.optimizely.com/" target='_blank'>Optimizely</a>
            </div>
            <date className='experience-headerContainer-duration'>March 2022 - Present</date>
        </div>
        <ul>
            <li>
Developed a python and node package for an event bus which will be used to standardize creation of event publishers and subscribers in different microservices
            </li>
            <li>
Collaborated with teams in Vietnam, Sweden and USA to build integrations which allowed
creation/editing of contents using content-types imported from external CMS platforms like Adobe Experience
Manager.
            </li>
            <li>
                Introduced analytics support for CMS contents which allowed marketers to track different content engagement metrics.
            </li>

            <li>
Worked on several migrations and refactoring (SQS to RabbitMQ, JS to TS, Angular 1.x to React etc)
            </li>
        </ul>


        {/* BS23 */}
        <div className='experience-headerContainer'>
            <div>
                <p className='experience-headerContainer-role'>Associate Software Engineer</p>
                <a className='experience-headerContainer-companyName' href="https://brainstation-23.com/?bc" target='_blank'>Brain Station 23</a>
            </div>
            <date className='experience-headerContainer-duration'>January 2022 - March 2022</date>
        </div>
        <ul>
            <li>
Worked on a boilerplate e-commerce website meant to be tailored to needs of individuals
            </li>
        </ul>
        {/* TechHack */}
        <div className='experience-headerContainer'>
            <div>
                <p className='experience-headerContainer-role'>Software Engineer Intern</p>
                <a className='experience-headerContainer-companyName' href="https://www.linkedin.com/company/techhack/?originalSubdomain=ca" target='_blank'>TechHack Canada</a>
            </div>
            <date className='experience-headerContainer-duration'>September 2021 -  January 2021</date>
        </div>
        <ul>
            <li>
            Implemented personality/recruitment tests for assessing developers applying for jobs in Canada through the
platform    
            </li>
            <li>
Introduced Redis to the codebase for improving response time of some endpoints
            </li>
            <li>
Added search and filter functionality in backend to allow a company to filter applicants for a job
            </li>
        </ul>
    </div>
}
