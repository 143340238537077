import React, { useRef } from "react";

const Games = () => {
  // Create a ref for the iframe
  const iframeRef = useRef(null);

  // Function to make the iframe go full screen
  const handleFullScreen = () => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;

      // If the browser supports the Fullscreen API
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        // Firefox
        iframe.mozRequestFullScreen();
      } else if (iframe.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) {
        // IE/Edge
        iframe.msRequestFullscreen();
      }
    }
  };
  return (
    <div className="w-full h-screen pb-[300px]">
      <h2 className="pb-[10px]">Why did the chicken cross the road?</h2>
      <iframe
        ref={iframeRef}
        className="h-[67%] w-[97%]"
        seamless={true}
        allowFullScreen={true}
        src="/chicken-game/www/index.html"
        title="Chicken Game"
        frameBorder="0"
      ></iframe>
      <button
        onClick={handleFullScreen}
        className="mt-4 px-6 py-2 bg-gray-500 text-white rounded-md"
      >
        Go Full Screen
      </button>
      <h2 className="experience-title App-sectionHeader">Controls</h2>
      <div className="pb-[20px]">
        <p className="underline">Phone/ Touch Screen:</p>
        <p> - Touch objects/screen for interaction/movement </p>
        <p> - Touch with two fingers simultaneously to enter/exit settings </p>
        <p className="underline">Keyboard:</p>
        <p>- Arrow keys for movement</p>
        <p>- Z for interaction</p>
        <p>- X for settings</p>
      </div>
    </div>
  );
};

export default Games;
