import React, { useContext } from "react";
import { DarkModeContext } from "../App";
import './Experience.scss'

export default function ResearchExperience() {

    const { isDarkMode } = useContext(DarkModeContext);

    return <div className='experience'>
        {/* Optimizely */}
    <h2 className='experience-title App-sectionHeader'>Research Experience</h2>
        <div className='experience-headerContainer'>
            <div>
                <p className='experience-headerContainer-role'>Research Assistant
          <p className= {`experience-headerContainer-prof aboutMe-description ${isDarkMode ? 'dark' : 'light'}`}>
            {" "} (under <a href="https://scholar.google.com/citations?user=t4GrJR4AAAAJ&hl=en" target="_blank">Prof. Golam Rabiul Alam</a>)
          </p>
        </p>

        <span className={`aboutMe-description ${isDarkMode ? 'dark' : 'light'}`}>
                <a className='experience-headerContainer-companyName' href="https://scholar.google.com/citations?view_op=view_citation&hl=en&user=H_1zL2sAAAAJ&citation_for_view=H_1zL2sAAAAJ:u5HHmVD_uO8C" target='_blank'>
              Ensembling CNN, Vision Transformer and Hybrid models to identify thoracic diseases

        </a>
        </span>
            </div>
            <date className='experience-headerContainer-duration'>2023-2024</date>
        </div>
        <ul>
{/*             <li> */}
{/*                 <a className='experience-headerContainer-companyName' href="https://scholar.google.com/citations?view_op=view_citation&hl=en&user=H_1zL2sAAAAJ&citation_for_view=H_1zL2sAAAAJ:u5HHmVD_uO8C" target='_blank'> */}
{/* Published in ICCIT 2023 */}
{/*         </a> */}
{/*             </li> */}
            <li>
The goal was to find out the effectiveness of ensemble deep learning in improving the accuracy of automatic diagnosis of thoracic diseases from chest X-rays
            </li>
            <li>
Trained several state-of-the-art transformers, CNNs and classical models to compare performance in detecting lung diseases
            </li>
            <li>
Built an ensemble model which achieved an ROC-AUC higher than previous work done on our dataset 
            </li>
        </ul>
        {/* BS23 */}
        <div className='experience-headerContainer'>
            <div>

                <p className='experience-headerContainer-role'>Undergrad thesis
          <p className= {`experience-headerContainer-prof aboutMe-description ${isDarkMode ? 'dark' : 'light'}`}>
            {" "} (under <a href="https://scholar.google.com/citations?user=t9cCPBAAAAAJ&hl=en" target="_blank">Prof. Amitabha Chakrabarty</a>)
          </p>
        </p>

        <span className={`aboutMe-description ${isDarkMode ? 'dark' : 'light'}`}>
                <a className='experience-headerContainer-companyName' href="https://www.researchgate.net/publication/353479465_Early_Schizophrenia_Diagnosis_with_3D_Convolutional_Neural_Network" target='_blank'>
Comparative performance analysis of 3DCNNs on early detection of Schizophrenia

        </a>
        </span>

        
            </div>
            <date className='experience-headerContainer-duration'>2021</date>
        </div>
        <ul>
            <li>
The goal was do a performance analysis of different existing 3D CNN architectures on detecting Schizophrenia in young people using fMRI images
            </li>
            <li>
Collected schizophrenic and healthy brain images of people aged between 16 and 30 from a public 

        <span className={`experience-headerContainer-companyName aboutMe-description ${isDarkMode ? 'dark' : 'light'}`}>
                <a  href="http://schizconnect.org" target='_blank'> dataset </a>
        </span>
      and preprocessed them using FSL before training the models
            </li>
        </ul>
    </div>
}
